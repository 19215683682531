<template>
  <div class="facilityBox">
    <div
      class="edit-enterprise"
      v-loading="loading"
    >
      <el-breadcrumb
        separator="/"
        style="margin:10px 0 0 0"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/bigCustomerManage/bigEnterpriseManage/bigEnterpriseManage' }">企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑企业信息</el-breadcrumb-item>
      </el-breadcrumb>

      <el-form
        :model="enterpriseForm"
        ref="enterpriseForm"
        label-width="160px"
        inline
        @submit.native.prevent
      >
        <div class="title">证照信息</div>
        <div class="content">
          <div
            class="upBox"
            v-for="(val , key, idx) in this.enterpriseImg"
            :key="idx"
          >
            <UploadImg
              :ref="`${key}ImgRef`"
              :loading="enterpriseImg[key].loading"
              :uploaded="enterpriseImg[key].uploaded"
              :picUrl="enterpriseImg[key].picUrl"
              @update:file="updateFile"
              :fileType="'Vehicle'"
              @change="upEnterpriseImg(enterpriseImg[key], key)"
              @deleteImg="deleteImg(key)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              {{  val.title  }}
            </div>
          </div>
        </div>

        <div class="title">基本信息</div>
        <div
          class="content content2"
          style="justify-content: left"
        >
          <el-form-item
            :label="it.title"
            :prop="it.key"
            v-for="(it, idx) in tableList"
            :key="idx"
            label-width="190px"
          >
            <el-input
              v-model="enterpriseForm[it.key]"
              :placeholder="`请输入${it.title}`"
              :maxlength="it.maxLength"
            >
              <template
                #append
                v-if="it.unit"
              >
                {{ it.unit }}
              </template>
            </el-input>
          </el-form-item>
        </div>

        <div class="footer-btns">
          <el-button
            size="medium"
            icon="el-icon-arrow-left"
            @click="routerBack()"
          >返回</el-button>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-check"
            @click="commitEnterprise"
          >确定
          </el-button>
        </div>
      </el-form>

    </div>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import { uploadImg } from '@/api/common/common.js'
import { detailImgList, tableList } from './data.js'
import { EnterpriseDetail, EditEnterprise } from '@/api/bigCustomerManage/index.js'

export default {
  data() {
    return {
      loading: false,
      currentFile: [], //当前正在操作的文件
      enterpriseImg: {},
      enterpriseForm: {},
      tableList
    }
  },
  created() {
    this.searchData = this.$route.query
    let { userId, enterpriseCreditCode } = this.$route.query

    this.enterpriseImg

    detailImgList.forEach(it => {
      this.enterpriseImg[it.key] = {
        title: it.title,
        loading: false,
        uploaded: false,
        picUrl: "",
        file: "",
      }
    })

    // console.log('this.enterpriseImg', this.enterpriseImg)

    this.getEnterpriseDetail({
      userId,
      enterpriseCreditCode
    })
  },
  methods: {
    getEnterpriseDetail(params) {
      this.loading = true;
      EnterpriseDetail(params)
        .then((res) => {
          this.enterpriseForm = res.data || {}
          for (var key in this.enterpriseImg) {
            this.enterpriseImg[key].picUrl = this.enterpriseForm[key] || ''
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },

    //删除图片
    deleteImg(field) {
      this.enterpriseImg = {
        ...this.enterpriseImg,
        [field]: {
          ...this.enterpriseImg[field],
          uploaded: false,
          loading: false,
          picUrl: ""
        }
      }
    },

    // 上传图片
    upEnterpriseImg(field, key) {
      console.log('field', field, 'key', key)

      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadImg(formData)
        .then(({ data = '' }) => {

          this.enterpriseImg = {
            ...this.enterpriseImg,
            [key]: {
              ...this.enterpriseImg[key],
              uploaded: true,
              loading: false,
              picUrl: data
            }
          }


          // field.loading = false;
          // field.uploaded = true;
          // field.picUrl = data;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs[`${key}ImgRef`][0].dialog();
        });
    },

    // 提交修改
    commitEnterprise() {
      this.$confirm('此操作将修企业信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.loading = true

          for (var key in this.enterpriseImg) {
            this.enterpriseForm[key] = this.enterpriseImg[key].picUrl
          }

          console.log('this.enterpriseForm', this.enterpriseForm)

          await EditEnterprise(this.enterpriseForm)
          this.$message({
            message: '修改成功',
            type: 'success'
          });

          setTimeout(() => {
            this.routerBack()
          }, 1000);

        } finally {
          this.loading = false
        }
      }).catch(() => {
      });
    },

    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/bigCustomerManage/bigEnterpriseManage/bigEnterpriseManage",
        query: {
          ...this.searchData
        }
      });
    },
  },
  components: {
    UploadImg
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';

.edit-enterprise {
  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 20px 0;
    margin-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }

  .footer-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}
</style>